import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";

import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import { useGetUserPolicyListQuery } from "../../store/feature/service/userPolicyApiSlice";
import KeyManagementTable from "../../components/molecules/keyManagement/KeyManagementTable";
import { useGetKeyManagementListQuery } from "../../store/feature/service/keyManagementApiSlice";

const KeyManagement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  const { data: dataKeyManagement, isLoading } =
    useGetKeyManagementListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    console.log("dataKeyManagement ===> ", dataKeyManagement);
  }, [dataKeyManagement]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Typography
            variant="h3"
            fontFamily="Open Sans"
            sx={{ marginY: "20px" }}
          >
            Key Management
          </Typography>
        </Grid>
        <Grid
          item
          xs={10}
          md={10}
          lg={10}
          xl={10}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/key-management/create")}
            sx={{ marginBottom: "18px" }}
          >
            <AddIcon /> Create
          </Button>
        </Grid>
        <Grid item xs={2} md={2} lg={2} xl={2}>
          <Typography variant="body16semibold"> Total : {dataKeyManagement?.count}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <KeyManagementTable
            data={
              dataKeyManagement !== undefined ? dataKeyManagement?.keys : []
            }
            params={params}
            setParams={setParams}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            totalData={dataKeyManagement?.count}
          />
        </Grid>
      </Grid>
    </ListDataSkeleton>
  );
};

export default KeyManagement;
