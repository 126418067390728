const optAES: any = [
  {
    label: "128",
    value: "128",
  },
  {
    label: "192",
    value: "192",
  },
  {
    label: "256",
    value: "256",
  },
];

const optRSA: any = [
  {
    label: "1024",
    value: "1024",
  },
  {
    label: "2048",
    value: "2048",
  },
  {
    label: "3072",
    value: "3072",
  },
  {
    label: "4096",
    value: "4096",
  },
];

const optHMAC: any = [
  {
    label: "128",
    value: "128",
  },
  {
    label: "160",
    value: "160",
  },
  {
    label: "224",
    value: "224",
  },
  {
    label: "256",
    value: "256",
  },
  {
    label: "384",
    value: "384",
  },
  {
    label: "512",
    value: "512",
  },
];
const optECC: any = [
  {
    label: "NistP256",
    value: "EC-P_256",
  },
  {
    label: "NistP384",
    value: "EC-P_384",
  },
  {
    label: "NistP521",
    value: "EC-P_521",
  },
  {
    label: "NistP256K1",
    value: "EC-P_256K",
  },
];

export { optAES, optRSA, optHMAC, optECC };
