import { Button, Grid, Box, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputForm from "../../components/molecules/forms/inputForm";
import type { RootState } from "../../store";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import LoginSchema from "../../utils/validation/login";
import { useNavigate } from "react-router-dom";
import { setEmailUser, setToken } from "../../utils/storage";
import {
  useConfirmPasswordMutation,
  useSubmitLoginMutation,
} from "../../store/feature/service/userApiSlice";
import Swal from "sweetalert2";
import {
  MailOutline as MailOutlineIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import indonesiaImg from "../../assets/logo/Indonesia.png";
import korlantasImg from "../../assets/logo/Indonesia.png";
import confirmPassword from "../../utils/validation/confirmPassword";
import autentikaImg from "../../assets/logo/medium.png";
import changePassword from "../../utils/validation/changePassword";
import entrustLogo from "../../assets/logo/entrustWhite.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
  const [isShowNewPass, setIsShowNewPass] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false); // toggle to show change password
  const [
    submitLogin,
    { error: errorLogin, isLoading, isError, isSuccess, data: successData },
  ] = useSubmitLoginMutation();
  const [
    confirmPassword,
    {
      error: errorDataConfirmPass,
      isError: errorConfirmPass,
      isSuccess: successConfirmPass,
    },
  ] = useConfirmPasswordMutation();
  const { accesToken } = useAppSelector((state: RootState) => state.user);

  interface ILogin {
    Email: string;
    email: string;
    password: string;
    showPass: boolean;
    newPassword: string;
    confirmNewPassword: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    reValidateMode: "onChange",
    resolver: yupResolver(isChangePassword ? changePassword : LoginSchema),
    defaultValues: {
      email: "",
      password: "",
      newPassword: "",
      confirmNewPassword: "",
      showPass: false,
    },
  });

  const onSubmit = async (data: ILogin) => {
    if (!isChangePassword) {
      submitLogin(data);
      setEmailUser(data?.Email);
    } else {
      confirmPassword(data);
      console.log("data ===> ", data);
    }
  };
  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const endAdornmentConfirm = useMemo(() => {
    if (isShowConfirmPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowConfirmPass]);

  const endAdornmentNewPass = useMemo(() => {
    if (isShowNewPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowNewPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const toggleShowPasswordConfirm = () => {
    setIsShowConfirmPass((prev) => !prev);
  };

  const toggleShowNewPassword = () => {
    setIsShowNewPass((prev) => !prev);
  };

  useEffect(() => {
    if (isError) {
      // let message = "Error Login, Please try again";
      // try {
      //   const dataError: any = errorLogin;
      //   message = dataError?.data?.error;
      //   console.log('haha ==> ', message)
      // if (message == "unauthorized user role") {
      //   message =
      //     "Incorrect username/password combination. Please check and try again";
      // }
      // } catch (e) {}
      const dataError: any = errorLogin;
      let message = dataError?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Error Login",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: message,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
    // }
  }, [isError]);

  useEffect(() => {
    if (accesToken) {
      setToken(accesToken);
      navigate("/", { replace: true });
    }
  }, [accesToken]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#051438", // Change this to your desired color
      },
    },
  });
  useEffect(() => {
    if (
      isSuccess &&
      successData?.message === "user need to change the password first"
    ) {
      Swal.fire({
        icon: "info",
        title: "Change Password Notification",
        showCancelButton: false,
        confirmButtonText: "Change Password",
        confirmButtonColor: "#051438",
        text: `Password change required. Please enter your new password on the next screen`,
        didOpen: () => setIsChangePassword(true),
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (successConfirmPass) {
      Swal.fire({
        icon: "success",
        title: "Success Create ",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create New Password",
        didOpen: () => setIsChangePassword(false),
      });
    }
  }, [successConfirmPass]);

  useEffect(() => {
    if (errorConfirmPass) {
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Create New Password",
        didOpen: () => setIsChangePassword(true),
      });
    }
  }, [errorConfirmPass]);

  return (
    <div
      style={{
        backgroundImage: `url(${indonesiaImg})`,
        backgroundSize: "cover",
        backgroundColor: "#051438",
        height: "auto",
        maxWidth: "100%",
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "105vh",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
          }}
        >
          <Stack
            style={{
              display: "grid",
              placeItems: "center", // Centers both horizontally and vertically
            }}
          >
            <Box
              bgcolor="#FFFFFF"
              borderRadius={"50%"}
              boxShadow={3}
              width={"100px"}
              height={"100px"}
              textAlign="center"
              marginBottom={"-50px"}
              zIndex={1000}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={autentikaImg} height={"75px"} width={"75px"} />
            </Box>
            <Box
              pt={9}
              pb={5}
              pl={5}
              pr={5}
              bgcolor="#FFFFFF"
              borderRadius={4}
              boxShadow={3}
              maxWidth="500px"
              width="100%"
              textAlign="center"
            >
              <Grid
                container
                direction="column"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", marginBottom: "1rem" }}
                  >
                    {isChangePassword
                      ? "Change Password "
                      : "Welcome to Tokenization"}
                  </Typography>
                </Grid>
                <Grid item>
                  <InputForm
                    name="Email"
                    errors={errors}
                    control={control}
                    required
                    placeholder="Email"
                    maxLength={100}
                    isLoading={false}
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    showStartAdornment
                    startAdornment={<MailOutlineIcon />}
                    sxFC={{ marginBottom: "1rem" }}
                  />
                </Grid>
                <Grid item>
                  <InputForm
                    name="Password"
                    errors={errors}
                    control={control}
                    type={isShowPass ? "text" : "password"}
                    placeholder={isChangePassword ? "Old Password" : "Password"}
                    maxLength={127}
                    showEndAdornment
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    endAdornment={endAdornment}
                    onClickEndAdornment={toggleShowPassword}
                    required
                    showStartAdornment
                    startAdornment={<LockIcon />}
                    sxFC={{ marginBottom: "1.5rem" }}
                  />
                </Grid>
                {isChangePassword && (
                  <>
                    <Grid item>
                      <InputForm
                        name="newPassword"
                        errors={errors}
                        control={control}
                        type={isShowNewPass ? "text" : "password"}
                        placeholder="New Password"
                        maxLength={127}
                        showEndAdornment
                        sxEndAdornment={{
                          backgroundColor: "inherit",
                          border: "none",
                          cursor: "pointer",
                          width: "4rem",
                        }}
                        endAdornment={endAdornmentNewPass}
                        onClickEndAdornment={toggleShowNewPassword}
                        required
                        showStartAdornment
                        startAdornment={<LockIcon />}
                        sxFC={{ marginBottom: "1.5rem" }}
                      />
                    </Grid>
                    <Grid item>
                      <InputForm
                        name="confirmNewPassword"
                        errors={errors}
                        control={control}
                        type={isShowConfirmPass ? "text" : "password"}
                        placeholder="Confirm New Password"
                        maxLength={127}
                        showEndAdornment
                        sxEndAdornment={{
                          backgroundColor: "inherit",
                          border: "none",
                          cursor: "pointer",
                          width: "4rem",
                        }}
                        endAdornment={endAdornmentConfirm}
                        onClickEndAdornment={toggleShowPasswordConfirm}
                        required
                        showStartAdornment
                        startAdornment={<LockIcon />}
                        sxFC={{ marginBottom: "1.5rem" }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item>
                  {isLoading ? (
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{ borderRadius: "12px", textTransform: "none" }}
                      >
                        {isChangePassword ? "Submit" : "Login"}
                      </LoadingButton>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ borderRadius: "12px", textTransform: "none" }}
                      >
                        {isChangePassword ? "Submit" : "Login"}
                      </Button>
                    </ThemeProvider>
                  )}
                </Grid>
                <Grid item sx={{ marginTop: "20px" }}>
                  <img src={entrustLogo} height={"50px"} width={"180px"} />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
