// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const userPolicyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserPolicyList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/user/policy`,
                    params: params
                }
            },
            providesTags:['GetUserPolicy'],
        }),
        createUserPolicy: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/user/policy`,
                    method: 'POST',
                    body

                })
            },
            invalidatesTags:['CreateUserPolicy', 'GetUserPolicy']
        }),
        deleteUserPolicy: builder.mutation<any, any>({
            query: params => {
                return ({
                    url: `/user/policy`,
                    method: 'DELETE',
                    body: params
                })
            },
            invalidatesTags:['DeleteUserPolicy', 'GetUserPolicy']
        }),
        editUserPolicy: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/user/policy`,
                    method: 'PUT',
                    body

                })
            },
            invalidatesTags:['EditUserPolicy', 'GetUserPolicy']
        }),
  })
})

export const {
useGetUserPolicyListQuery, 
useCreateUserPolicyMutation,
useDeleteUserPolicyMutation,
useEditUserPolicyMutation
} = userPolicyApiSlice;