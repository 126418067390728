import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  getAllOcspList,
  removeEventFlag,
} from '../../store/feature/satpasDevice';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import AddIcon from '@mui/icons-material/Add';
import UserPolicyTable from '../../components/molecules/userPolicy/UserPolicyTable';
import { useGetUserPolicyListQuery } from '../../store/feature/service/userPolicyApiSlice';

const UserPolicy = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data: dataUserPolicy, isLoading } = useGetUserPolicyListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    console.log('datauserpolicy ===> ', dataUserPolicy)
  }, [dataUserPolicy]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          User Policy
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/user-policy/create')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Create
        </Button>
        {/* <LicenseServerSearch
        params={params}
        setParams={setParams}
      /> */}
        <UserPolicyTable
          data={dataUserPolicy !== undefined ? dataUserPolicy?.policies: []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          totalData={dataUserPolicy?.count}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default UserPolicy;
