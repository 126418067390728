import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  getAllOcspList,
  removeEventFlag,
} from '../../store/feature/satpasDevice';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import LogEventsSearch from '../../components/molecules/logEvents/LogEventsSearch';
import LogEventsTable from '../../components/molecules/logEvents/LogEventsTable';
import { useGetLogEventsListQuery } from '../../store/feature/service/logEventsApiSlice';

const LogEvents = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data: dataLogEvents, isLoading } = useGetLogEventsListQuery(params);

  console.log('data ===> ', dataLogEvents)
 
  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      // dispatch(getAllOcspList(params));
      dispatch(removeEventFlag());
    }
  }, [eventFlag]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Event Log
        </Typography>
        <LogEventsSearch params={params} setParams={setParams} />
        <LogEventsTable
          data={dataLogEvents !== undefined ? dataLogEvents?.audit_messages : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          totalData={dataLogEvents?.total_records}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default LogEvents;
