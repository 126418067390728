import IRoutes from "../interfaces/IRoutes";
import TokenPolicy from "../pages/tokenPolicy/TokenPolicy";
import TokenPolicyField from "../pages/tokenPolicy/TokenPolicyField";

const TokenPolicyRoute: Array<IRoutes> = [
  {
    element: <TokenPolicy />,
    path: "/token-policy",
  },
  {
    element: <TokenPolicyField/>,
    path: "/token-policy/create",
  },
  {
    element: <TokenPolicyField />,
    path: "/token-policy/edit/:id",
  },
];

export { TokenPolicyRoute };
