// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const keyManagementApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getKeyManagementList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/keys`,
                    params: params
                }
            },
            providesTags:['GetKeyManagementList'],
        }),
        createKeyManagement: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/key`,
                    method: 'POST',
                    body

                })
            },
            invalidatesTags:['CreateKeyManagement', 'GetKeyManagementList']
        }),
        deleteKeyManagement: builder.mutation<any, any>({
            query: params => {
                return ({
                    url: `/key/${params}`,
                    method: 'DELETE',
                })
            },
            invalidatesTags:['DeleteKeyManagement', 'GetKeyManagementList']
        }),
        rotateKey: builder.mutation<any, any>({
            query: params => {
                return ({
                    url: `/key/rotate/${params}`,
                    method: 'POST'

                })
            },
            invalidatesTags:['RotateKey']
        }),
  })
})

export const {
useGetKeyManagementListQuery, 
useCreateKeyManagementMutation,
useDeleteKeyManagementMutation,
useRotateKeyMutation
} = keyManagementApiSlice;