import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { IOpenMainProps } from "../../../interfaces/IUtils";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { clearStorage } from "../../../utils/storage";
import { useAppDispatch } from "../../../store";
import { removeToken } from "../../../store/feature/userReducer";
import { useLogoutMutation } from "../../../store/feature/service/userApiSlice";

const drawerWidth = 320;
const Navbar = ({ open, setOpen }: IOpenMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const settings = ["Change Password", "Logout"];
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [offset, setOffset] = useState(0);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { pathname } = useLocation();
  const param = useParams();
  const [
    UserLogout,
    {
      data: logoutPayload,
      isLoading: logoutLoading,
      isSuccess: logoutSuccess,
      isError: logoutError,
    },
  ] = useLogoutMutation();
  const breadcrumb: any = [{ label: "Dashboard", href: "/" }];

  // REGION: BREADCRUMB NON DYMANIC
  if (pathname === "/user") {
    breadcrumb.push({ label: "Local User", href: "/user" });
  } else if (pathname === "/user/create") {
    breadcrumb.push({ label: "Local User", href: "/user" });
    breadcrumb.push({ label: "Create User ", href: "/user/create" });
  } else if (pathname === `/user/detail/${param.id}`) {
    breadcrumb.push({ label: "Local User", href: "/user" });
    breadcrumb.push({
      label: "Detail User ",
      href: `/user/detail/${param.id}`,
    });
  } else if (pathname === "/user-policy") {
    breadcrumb.push({ label: "User Policy", href: "/user-policy" });
  } else if (pathname === "/user-policy/create") {
    breadcrumb.push({ label: "User Policy", href: "/user-policy" });
    breadcrumb.push({
      label: "Create User Policy ",
      href: "/user-policy/create",
    });
  } else if (pathname === `/user-policy/add/${param.id}`) {
    breadcrumb.push({ label: "User Policy", href: "/user-policy" });
    breadcrumb.push({
      label: "Add User Policy ",
      href: `/user-policy/add/${param.id}`,
    });
  } else if (pathname === "/event-log") {
    breadcrumb.push({ label: "Event Log", href: "/event-log" });
  } else if (pathname === "/log-result") {
    breadcrumb.push({ label: "Log Result", href: "/log-result" });
  } else if (pathname === "/masking-policy") {
    breadcrumb.push({ label: "Masking Policy", href: "/masking-policy" });
  } else if (pathname === "/masking-policy/create") {
    breadcrumb.push({ label: "Masking Policy", href: "/masking-policy" });
    breadcrumb.push({
      label: "Create Masking Policy ",
      href: "/masking-policy/create",
    });
  } else if (pathname === `/masking-policy/edit/${param.id}`) {
    breadcrumb.push({ label: "Masking Policy", href: "/masking-policy" });
    breadcrumb.push({
      label: "Detail Masking Policy ",
      href: `/masking-policy/edit/${param.id}`,
    });
  } else if (pathname === `/masking-policy/change-password/${param.id}`) {
    breadcrumb.push({ label: "Masking Policy", href: "/masking-policy" });
    breadcrumb.push({
      label: "Change Password ",
      href: `/masking-policy/change-password/${param.id}`,
    });
  } else if (pathname === "/token-policy") {
    breadcrumb.push({ label: "Token Policy", href: "/token-policy" });
  } else if (pathname === "/token-policy/create") {
    breadcrumb.push({ label: "Token Policy", href: "/token-policy" });
    breadcrumb.push({
      label: "Create Token Policy ",
      href: "/token-policy/create",
    });
  } else if (pathname === `/token-policy/edit/${param.id}`) {
    breadcrumb.push({ label: "Token Policy", href: "/token-policy" });
    breadcrumb.push({
      label: "Detail Token Policy ",
      href: `/token-policy/edit/${param.id}`,
    });
  } else if (pathname === `/token-policy/change-password/${param.id}`) {
    breadcrumb.push({ label: "Token Policy", href: "/token-policy" });
    breadcrumb.push({
      label: "Change Password ",
      href: `/token-policy/change-password/${param.id}`,
    });
  } else if (pathname === "/key-management") {
    breadcrumb.push({ label: "Key Management", href: "/key-management" });
  } else if (pathname === "/key-management/create") {
    breadcrumb.push({ label: "Key Management", href: "/key-management" });
    breadcrumb.push({
      label: "Create Key Management",
      href: "/key-management/create",
    });
  } else if (pathname === `/key-management/edit/${param.id}`) {
    breadcrumb.push({ label: "Key Management", href: "/key-management" });
    breadcrumb.push({
      label: "Detail Key Management",
      href: `/key-management/edit${param.id}`,
    });
  }

  useEffect(() => {
    // useEffect(() => {
    //   if (idParam.id !== undefined) {
    //     dispatch(getOcspById(idParam.id));
    //   }
    //   dispatch(getCertificateDropdown({}));
    // }, []);

    // // REGION: DISPATCH SUCCESS EVENT
    // useEffect(() => {
    //   if(eventFlag) {
    //     navigate("/user")
    //     dispatch(removeEventFlag())
    //   }
    // }, [eventFlag]);
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButton = (setting: any) => {
    if (setting === "Logout") {
      UserLogout("");
      dispatch(removeToken());
      navigate("/login", { replace: true });
      clearStorage();
    } else if (setting === "Change Password") {
      navigate("/change-new-password");
    }
  };

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: "flex",
        background: "transparent",
        // background: 'yellow',
        boxShadow: "none",
        // backgroundColor: '#B3c100',
        alignItems: "space-between",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar sx={{ borderRadius: "none", paddingLeft: 0 }} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 3.7,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {offset <= 30 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                backgroundColor: "transparent",
                flex: 1,
                marginTop: "12px",
                cursor: "pointer",
              }}
            >
              {breadcrumb.map((item: any, index: Number) => {
                return index != breadcrumb.length - 1 ? (
                  <Link
                    key={item.label}
                    onClick={() => navigate(item.href)}
                    underline="hover"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Typography color="textPrimary">{item.label}</Typography>
                );
              })}
            </Breadcrumbs>
          )}

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                {/* <Typography textAlign="center">{setting}</Typography> */}
                <Button
                  onClick={() => handleButton(setting)}
                  sx={{
                    width: "100%",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    paddingX: "12px",
                  }}
                >
                  {setting}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer */}
        {/* </Typography> */}
        {/* <Navbar /> */}
      </Toolbar>
    </MuiAppBar>
  );
};

export default Navbar;
