import IRoutes from "../interfaces/IRoutes";
import MaskingPolicy from "../pages/maskingPolicy/MaskingPolicy";
import MaskingPolicyField from "../pages/maskingPolicy/MaskingPolicyField";

const MaskingPolicyRoute: Array<IRoutes> = [
    {
        element: <MaskingPolicy />,
        path: "/masking-policy",
    },
    {
        element: <MaskingPolicyField />,
        path: "/masking-policy/create",
    },
    {
        element: <MaskingPolicyField />,
        path: "/masking-policy/edit/:id",
    },
];

export { MaskingPolicyRoute };
