import IRoutes from "../interfaces/IRoutes";
import KeyManagement from "../pages/keyManagement/KeyManagement";
import KeyManagementField from "../pages/keyManagement/KeyManagementField";

const KeyManagementRoute: Array<IRoutes> = [
    {
        element: <KeyManagement />,
        path: "/key-management",
    },
    {
        element: <KeyManagementField />,
        path: "/key-management/create",
    },
    {
        element: <KeyManagementField />,
        path: "/key-management/edit/:id",
    },
];

export { KeyManagementRoute };
