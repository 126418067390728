import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteUserLocalMutation } from "../../../store/feature/service/userLocal";
import { useEffect } from "react";

const UserTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  totalData
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );

  const [deleteUserLocal, { isSuccess, isError }] =
    useDeleteUserLocalMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/user/detail/${params.row.id}`, { state: params.row });
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUserLocal(params.row.username)
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Details
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createAt",
      headerName: "Create at",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "loginTime",
      headerName: "Login Time",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  console.log("data from userTable ===> ", data);
  // REGION: INIT ROWS
  const rows = data.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    username: row.username,
    name: row.name,
    email: row.email,
    createAt: row.created_at,
    loginTime: row.login_time,
    loginIp: row.login_ip,
    revision: row.revision,
    localUserId: row.local_user_id
  }));
  useEffect(()=> {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Deleted",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete User Local",
      });
    }  
  }, [isError])

  useEffect(()=> {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Deleted",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete User Local",
      }).then(() => navigate("/user"));
    }
  }, [isSuccess])
  
  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalData}
      />
    </>
  );
};

export default UserTable;
