import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import {
  getAllOcspList,
  removeEventFlag,
} from "../../store/feature/satpasDevice";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import TokenPolicySearch from "../../components/molecules/tokenPolicy/TokenPolicySearch";
import TokenPolicyTable from "../../components/molecules/tokenPolicy/TokenPolicyTable";
import { useGetTokenPolicyListQuery } from "../../store/feature/service/tokenPolicyApiSlice";

const LivenessDevice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data: dataTokenPolicy, isLoading } =
    useGetTokenPolicyListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    console.log("hahaha ===> ", dataTokenPolicy);
  }, [dataTokenPolicy]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Token Policy
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/token-policy/create")}
          sx={{ marginBottom: "18px" }}
        >
          <AddIcon /> Create
        </Button>
        <TokenPolicySearch params={params} setParams={setParams} />
        <TokenPolicyTable
          data={
            dataTokenPolicy !== undefined &&
            dataTokenPolicy?.token_policies !== null
              ? dataTokenPolicy?.token_policies
              : []
          }
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          totalData={dataTokenPolicy?.count}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default LivenessDevice;
