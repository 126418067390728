import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useState } from "react";

const LogEventsTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  totalData,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );
  const [pagesize, setPagesize] = useState(20);

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "host",
      headerName: "Host",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "info",
      headerName: "Info",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "msgType",
      headerName: "Message Type",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  // Handle Load More
  const handleLoadMore = () => {
    const newPagesize = pagesize + 10;
    setPagesize(newPagesize);
    setParams({ ...params, pagesize: newPagesize });
  };

  console.log("data here ", data);
  // REGION: INIT ROWS
  const rows = data?.map((row: any, index: any) => ({
    id: index,
    // uid: row.uc_ocsp_config_uid,
    // module: "Auth",
    host: row.host,
    info: row.info.client_ip,
    msgType: row.message_type,
    message: row.message,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button onClick={handleLoadMore} color="inherit" variant="contained">Load More</Button>
      </Box>
    </>
  );
};

export default LogEventsTable;
