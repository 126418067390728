import * as yup from "yup";
import { validationNotEmpty, validationWithoutSpace, } from "./defaultValidations";

export default yup.object({
  policyName: validationWithoutSpace("policyName"),
  charset: validationNotEmpty("Charset"),
  keyName: validationNotEmpty("Key Name"),
  perSuff: validationNotEmpty("Perserved Suffix Length"),
  perPrefix: validationNotEmpty("Perserved Prefix Length"),
  space: validationNotEmpty("Space"),
  alphabet: validationNotEmpty("Alphabet"),
  fullWidth: validationNotEmpty("Full Width"),
  symbol: validationNotEmpty("Symbol"),
  // option1: validationNotEmpty("Charset Option 2"),
  // option2: validationNotEmpty("Charset Option 2"),
});

//lanjut untuk alphanumericnya sob