import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  getAllOcspList,
  removeEventFlag,
} from '../../store/feature/satpasDevice';
import UserSearch from '../../components/molecules/user/UserSearch';
import UserTable from '../../components/molecules/user/UserTable';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import AddIcon from '@mui/icons-material/Add';
import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';
import { useGetUserLocalListQuery } from '../../store/feature/service/userLocal';

const User = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data, isLoading } = useGetUserLocalListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (data) {
      console.log('data ===> ', data)
    }
  }, [data]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Local User
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/user/create')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Create
        </Button>
        <UserSearch params={params} setParams={setParams} />
        <UserTable
          data={data !== undefined && data?.local_users !== null ? data?.local_users : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          totalData={data?.count}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default User;
