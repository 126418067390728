import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteMaskPolicyMutation } from "../../../store/feature/service/maskingPolicyApiSlice";

const MaskingPolicyTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  totalData
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );
  const [deleteMaskPolicy, { isSuccess: deleteSuccess, isError: deleteError }] =
    useDeleteMaskPolicyMutation();

    if(deleteSuccess){
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Token Policy has been deleted",
      })
    }

    if (deleteSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Deleted",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Mask Policy has been deleted",
      })
    }

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/masking-policy/edit/${params.row.policyName}`);
    };

    const onChangePassword = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/masking-policy/change-password/${params.row.policyName}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMaskPolicy(params.row.policyName);
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Details
          </Button>
          {/* <Button variant="contained" color="gray" onClick={onChangePassword}>
          Change Password
        </Button> */}
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "policyName",
      headerName: "Policy Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "perservedSuffixLength",
      headerName: "Preserved Suffix Length",
      flex: 1,
      minWidth: 275,
    },
    {
      field: "perservedPrefixLength",
      headerName: "Preserved Prefix Length",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 170,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params: any) => {
    //     return (
    //       <>
    //         {params.row.status !== null ? (
    //           params.row.status.toLowerCase() === "active" ? (
    //             <Chip label="ACTIVE" color="success" variant="outlined" />
    //           ) : (
    //             <Chip label="INACTIVE" color="warning" variant="outlined" />
    //           )
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "charset",
      headerName: "Charset",
      minWidth: 225,
    },
    // {
    //   field: "lastUpdated",
    //   headerName: "Last Updated",
    //   minWidth: 225,
    // },
    // {
    //   field: "lastLogin",
    //   headerName: "Last Login",
    //   minWidth: 225,
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 375,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  console.log("table masking policy ===> ", data);
  // REGION: INIT ROWS
  const rows = data?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    policyName: row.name,
    perservedSuffixLength: row.preservedSuffixLength,
    perservedPrefixLength: row.preservedPrefixLength,
    charset: row.charset,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalData}
      />
    </>
  );
};

export default MaskingPolicyTable;
