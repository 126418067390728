import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  RadioForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import adminManagement from "../../utils/validation/maskPolicy";
import {
  useCreateTokenPolicyAlphaMutation,
  useCreateTokenPolicyNumMutation,
  useGetTokenPolicyDetailMutation,
} from "../../store/feature/service/tokenPolicyApiSlice";
import numericTokenP from "../../utils/validation/numericTokenP";
import Swal from "sweetalert2";
import alphanumericTokenP from "../../utils/validation/alphanumericTokenP";
import { useGetKeyManagementListQuery } from "../../store/feature/service/keyManagementApiSlice";

const TokenPolicyField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { pathname, state: dataDetail } = location;
  const editFgroup = pathname.includes("edit");
  const { editDataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const { uc_ocsp_config_uid } = editDataOcspConfig;
  const [charset, setCharset] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [getTokenPolicyDetail, { data: dataTokenPol }] =
    useGetTokenPolicyDetailMutation();
  const [optKeyName, setOptKeyName] = useState([]);
  const [
    createTokenPolicyNum,
    { isSuccess: successNum, isError: errorNum, error: errDataNum },
  ] = useCreateTokenPolicyNumMutation();
  const [
    createTokenPolicyAlpha,
    { isSuccess: successAlpha, isError: errorAlpha, error: errDataAlpha },
  ] = useCreateTokenPolicyAlphaMutation();

  const { data: dataKeyManagement, isLoading } = useGetKeyManagementListQuery(
    {}
  );

  const optStatusSelect = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];
  const optCharset = [
    {
      label: "Numeric",
      value: "Numeric",
    },
    {
      label: "Alphanumeric",
      value: "Alphanumeric",
    },
  ];

  useEffect(() => {
    if (dataKeyManagement) {
      setOptKeyName(
        dataKeyManagement?.keys.map((item: any) => ({
          label: item.name,
          value: item.guid,
        }))
      );
    }
  }, [dataKeyManagement]);

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    uid: string;
    policyName: string;
    charset: string;
    keyName: string;
    perSuff: string;
    perPrefix: string;
    space: string;
    alphabet: string;
    digit: string;
    fullWidth: string;
    symbol: string;
    // option1: string;
    // option2: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(charset ? alphanumericTokenP : numericTokenP),
    defaultValues: {
      uid: "",
      policyName: "",
      charset: "",
      keyName: "",
      perSuff: "",
      perPrefix: "",
      space: "",
      alphabet: "",
      digit: "",
      fullWidth: "",
      symbol: "",
      // option1: "",
      // option2: "",
    },
  });

  // REGION: SET FORM DATA
  useEffect(() => {
    if (dataTokenPol) {
      reset({
        policyName: dataTokenPol.name,
        charset: dataTokenPol.charset,
        keyName: dataTokenPol.keyName,
        perSuff: dataTokenPol.preservedSuffixLength,
        perPrefix: dataTokenPol.preservedPrefixLength,
        space: dataTokenPol.space,
        fullWidth: dataTokenPol.fullwidth,
        symbol: dataTokenPol.symbol,
        // digit: dataTokenPol.digit
      });
    } else {
      reset();
    }
    setValue("digit", "true");
    setValue("alphabet", "true");
  }, [dataTokenPol]);

  // REGION: DISPATCH GET DATA
  useEffect(() => {
    console.log("layaknya sang penyair yang indah ===> ", dataDetail);
  }, [dataDetail]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      navigate("/masking-policy");
      // dispatch(removeEventFlag())
    }
  }, [eventFlag]);

  useEffect(() => {
    if (idParam) {
      getTokenPolicyDetail(idParam);
    }
  }, [idParam]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    let tempNum = {
      keyGuid: e.keyName,
      name: e.policyName,
      preservedPrefixLength: Number(e.perPrefix),
      preservedSuffixLength: Number(e.perSuff),
    };
    let charOpt = [4, 8];
    if (e.fullWidth === "true") {
      charOpt.push(16);
    }
    if (e.space === "true") {
      charOpt.push(1);
    }
    if (e.symbol === "true") {
      charOpt.push(2);
    }
    let tempAlpha = {
      keyGuid: e.keyName,
      name: e.policyName,
      preservedPrefixLength: Number(e.perPrefix),
      preservedSuffixLength: Number(e.perSuff),
      charsetOption: charOpt,
      alphabet: true,
      digit: true,
      fullwidth: e.fullWidth === "true",
      space: e.space === "true",
      symbol: e.symbol === "true",
    };

    if (charset) {
      createTokenPolicyAlpha(tempAlpha);
    } else {
      createTokenPolicyNum(tempNum);
    }
    // console.log('tempAlpha ===> ', tempAlpha)
    // console.log('tempNum ===> ', tempNum)
  };

  const optRadio = [
    {
      label: "true",
      value: "true",
    },
    {
      label: "false",
      value: "false",
    },
  ];

  const handleCharset = (e: any) => {
    console.log("wkwkwk haha ===> ", e);
    if (e.value === "Alphanumeric") {
      setCharset(true);
    } else {
      setCharset(false);
    }
  };

  useEffect(() => {
    if (errorNum) {
      const error: any = errDataNum;
      console.log("error data ==> ", error);
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: error?.data?.error,
      });
    }
  }, [errorNum]);

  useEffect(() => {
    if (errorAlpha) {
      const error: any = errDataAlpha;
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: error?.data?.error,
      });
    }
  }, [errorAlpha]);

  if (successNum || successAlpha) {
    Swal.fire({
      icon: "success",
      title: "Success Create",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Token Policy has been Created",
    }).then(() => navigate("/token-policy"));
  }


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleInfo = (event: any) => {
    // console.log('info brooo ===> ')
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup ? "Detail Token Policy" : "Create Token Policy"}
        </Typography>

        <InputForm
          name="policyName"
          label="Policy Name & ID"
          placeholder=" Type Policy Name & ID "
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={12}
          required
          info={handleInfo}
          id={id}
          infoData={true}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            This field will become a Policy ID, you can enter maximum 12 <br />
            characters, and the system will add date after the value.
          </Typography>
        </Popover>

        <SelectFormm
          name="charset"
          label="Charset"
          defaultValue={0}
          options={optCharset}
          placeholder="Please Select"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          // isSearch
          onChangeForm={handleCharset}
          required
        />
        {!editFgroup ? (
          <SelectFormm
            name="keyName"
            label="Key Name"
            defaultValue={0}
            options={optKeyName}
            placeholder="Please Select"
            disabled={editFgroup}
            isLoading={false}
            control={control}
            errors={errors}
            // isSearch
            // onChangeForm={handleCharset}
            required
          />
        ) : (
          <InputForm
            name="keyName"
            label="Key Name"
            placeholder="Type Key Name"
            disabled={editFgroup}
            isLoading={false}
            control={control}
            errors={errors}
            maxLength={500}
            required
          />
        )}

        <InputForm
          name="perPrefix"
          label="Preserved Prefix Length"
          placeholder="Type Preserved Prefix Length"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />
        <InputForm
          name="perSuff"
          label="Preserved Suffix Length"
          placeholder="Type Preserved Suffix Length"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />

        {editFgroup && (
          <>
            <RadioForm
              name="alphabet"
              label="Alphabet"
              disabled={true}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={[
                {
                  label: "true",
                  value: "true",
                },
              ]}
              defaultValue={1}
            />
            <RadioForm
              name="digit"
              label="Digit"
              disabled={true}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={[
                {
                  label: "true",
                  value: "true",
                },
              ]}
              defaultValue={1}
            />
            <RadioForm
              name="fullWidth"
              label="FullWidth"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={optRadio}
              defaultValue={1}
            />
            <RadioForm
              name="space"
              label="Space"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={optRadio}
              defaultValue={1}
            />
            <RadioForm
              name="symbol"
              label="Symbol"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={optRadio}
              defaultValue={1}
            />
          </>
        )}

        {charset && (
          <>
            <RadioForm
              name="alphabet"
              label="Alphabet"
              disabled={true}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={[
                {
                  label: "true",
                  value: "true",
                },
              ]}
              defaultValue={1}
            />
            <RadioForm
              name="digit"
              label="Digit"
              disabled={true}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={[
                {
                  label: "true",
                  value: "true",
                },
              ]}
              defaultValue={1}
            />
            <RadioForm
              name="fullWidth"
              label="FullWidth"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={optRadio}
              defaultValue={1}
            />
            <RadioForm
              name="space"
              label="Space"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={optRadio}
              defaultValue={1}
            />
            <RadioForm
              name="symbol"
              label="Symbol"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={optRadio}
              defaultValue={1}
            />
          </>
        )}

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/token-policy")}
          >
            {editFgroup ? "Back" : "Cancel"}
          </Button>
          {/* {editFgroup && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )} */}
          {!editFgroup && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default TokenPolicyField;
