import {
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import CloseIcon from "@mui/icons-material/Close";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import { useState } from "react";
import { useDeleteTokenPolicyMutation } from "../../../store/feature/service/tokenPolicyApiSlice";
import Swal from "sweetalert2";

const TokenPolicyTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  totalData
}: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [deleteTokenPolicy, {data: dataTokenPolicy, isSuccess, isError}] = useDeleteTokenPolicyMutation()

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const handleClose = () => {
    setOpen(false);
  };

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onDetail = (e: any) => {
      e.stopPropagation();
      navigate(`/token-policy/edit/${params.row.policyName}`)
    };

    const onDelete = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this Token Policy",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteTokenPolicy(params.row.policyName)
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
          <Button color="error" variant="contained" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "policyName",
      headerName: "Policy Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "keyName",
      headerName: "Key Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "charset",
      headerName: "Charset",
      flex: 1,
      minWidth: 225,
    },
    {
      field: "perservedSuffixLength",
      headerName: "Preserved Suffix Length",
      flex: 1,
      minWidth: 275,
    },
    {
      field: "perservedPrefixLength",
      headerName: "Preserved Prefix Length",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  console.log('data from table token policy ===> ', data)

  // REGION: INIT ROWS
  const rows = data?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    policyName: row.name,
    keyName: row.keyName,
    charset: row.charset,
    perservedSuffixLength: row.preservedSuffixLength,
    perservedPrefixLength: row.preservedPrefixLength,
  }));

  if (isSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Deleted",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Token Policy has been deleted",
    })
  }

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "600px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalData}
      />
    </>
  );
};

export default TokenPolicyTable;
