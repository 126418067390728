import * as yup from "yup";
import { validationNotEmpty, validationWithoutSpace, } from "./defaultValidations";

export default yup.object({
  policyName: validationWithoutSpace("policyName"),
  charset: validationNotEmpty("Charset"),
  maskChar: validationNotEmpty("Mask Char"),
  persSuffix: validationNotEmpty("Perserved Suffix Length"),
  persPre: validationNotEmpty("Perserved Prefix Length"),
  // space: validationNotEmpty("Status"),
  // alphabet: validationNotEmpty("Password"),
  // symbol: validationNotEmpty("Confirm Password"),
});