import { apiSlice } from "../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitLogin: builder.mutation<any, any>({
      query: (credentials) => {
        const { Email, Password } = credentials;
        const pasEnc = Password;
        return {
          url: "/login",
          method: "POST",
          body: {
            username: Email,
            password: Password,
          },
        };
      },
      invalidatesTags: ["submitLogin"],
    }),
    confirmPassword: builder.mutation<any, any>({
      query: (credentials) => {
        const { Email, Password, newPassword } = credentials;
        const pasEnc = Password;
        return {
          url: "/changepassword",
          method: "PUT",
          body: {
            email: Email,
            password: Password,
            new_password: newPassword,
          },
        };
      },
      invalidatesTags: ["confirmPassword"],
    }),
    confirmPasswordPage: builder.mutation<any, any>({
      query: (credentials) => {
        const { Email, Password, newPassword } = credentials;
        const pasEnc = Password;
        return {
          url: "/changepassword",
          method: "PUT",
          body: {
            email: Email,
            password: Password,
            new_password: newPassword,
          },
        };
      },
      invalidatesTags: ["confirmPasswordPage"],
    }),
    logout: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/logout`,
          method: "POST",
        };
      },
      invalidatesTags: ["logoutUser"],
    }),
  }),
});

export const {
  useSubmitLoginMutation,
  useConfirmPasswordPageMutation,
  useConfirmPasswordMutation,
  useLogoutMutation,
} = userApiSlice;
