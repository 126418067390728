import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  clearStorage,
  getToken,
  getTokenRegister,
} from "../../../utils/storage";
import Swal from "sweetalert2";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_API}/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getToken();
    const tokenRegister = getTokenRegister();
    if (process.env.NODE_ENV === "development") {
      headers.set("x-mock-response-code", "200");
    }
    if (token) {
      headers.set("X-TOKEN-AUTH", `${token}`);
    }
    // console.log('endpoint ==> ', endpoint)
    if (endpoint === "confirmPassword") {
      headers.set("X-TOKEN-AUTH", `${tokenRegister}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  let error: any = result?.error?.data;
  if (
    error?.error === "tokenization Error: Access token expired" ||
    error?.error === "error on finding user" ||
    error?.error === "error on finding session" ||
    error?.error === "user is not active" ||
    error?.error === "user is not authorized to this application" ||
    error?.error === "error on login temp"
  ) {
    clearStorage();
    window.location.href = "/login";
  } else if (error?.error === "unauthorized user role") {
    Swal.fire({
      icon: "error",
      title: "Unauthorized Role",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "unauthorized user role",
    }).then(() => {
      clearStorage();
      window.location.href = "/login";
    });
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    "GetAllOcspList",
    "GetLicenseServerList",
    "GetLivenessDeviceList",
    "GetLogEventsList",
    "GetUserLocalList",
    "GetTokenPolicyList",
    "GetTokenPolicyDetail",
    "DeleteTokenPolicy",
    "GetUserPolicy",
    "CreateUserPolicy",
    "DeleteUserPolicy",
    "GetMaskPolicyDetail",
    "GetMaskingPolicyList",
    "CreateMaskingPolicy",
    "DeleteMaskingPolicy",
    "CreateUserLocal",
    "DeleteUserLocal",
    "EditUserPolicy",
    "CreateTokenPolicyNUm",
    "CreateTokenPolicyAlpha",
    "GetDashboardInfo",
    "ConfirmPassword",
    "UpdatePassword",
    "submitLogin",
    "confirmPassword",
    "confirmPasswordPage",
    "logoutUser",
    "GetKeyManagementList",
    "CreateKeyManagement",
    "DeleteKeyManagement",
    "RotateKey",
  ],
});

export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
