import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";

const schema = yup.object({
  title: yup.string().required(),
});

const LogEventsSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [host, setHost] = useState("");
  const [info, setInfo] = useState("");
  const [msgType, setMsgType] = useState("");
  const [message, setMessage] = useState("");
  const [disabledHost, setDisabledHost] = useState(false);
  const [disabledInfo, setDisabledInfo] = useState(false);
  const [disabledMsgType, setDisabledMsgType] = useState(false);
  const [disabledMessage, setDisabledMessage] = useState(false);
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    host: string;
    info: string;
    msgType: string;
    message: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      host: "",
      info: "",
      msgType: "",
      message: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Information",
      value: "Information",
    },
    {
      label: "Warning",
      value: "Warning",
    },
    // {
    //   label: "High",
    //   value: "HIGH",
    // },
  ];

  const handleHost = (e: string) => { setHost(e); };
  const handleInfo = (e: any) => { setInfo(e); };
  const handleMsgTypeSelect = (e: any) => { if(e !== null){setMsgType(e.value)} else { setMsgType("")}; }; 
  const handleMessage = (e: any) => { setMessage(e); };

  useDebouncedEffect(() => setSearchFilterDebounced(host), [host], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(info), [info], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(msgType), [msgType], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(message), [message], 500);
  
  useEffect(() => {
    // Disable other fields if msgType is filled
    if (msgType.length > 0) {
      setDisabledHost(true);
      setDisabledInfo(true);
      setDisabledMessage(true);
      setParams({...params, filter: `startswith(/message_type,'${msgType}')`})
    } else {
      setDisabledHost(false);
      setDisabledInfo(false);
      setDisabledMessage(false);
      setParams({pagesize: 20})
    }
    console.log('msgType ===> ', msgType);
  
  }, [msgType]);
  
  useEffect(() => {
    // Disable other fields if message is filled
    if (message.length > 0) {
      setDisabledHost(true);
      setDisabledInfo(true);
      setDisabledMsgType(true);
      setParams({...params, filter: `startswith(/message,'${message}')`})
    } else {
      setDisabledHost(false);
      setDisabledInfo(false);
      setDisabledMsgType(false);
      setParams({pagesize: 20})
    }
    console.log('message ===> ', message);
  }, [message]);
  
    useEffect(() => {
    // Disable other fields if host is filled
    if (host.length > 0) {
      setDisabledInfo(true);
      setDisabledMsgType(true);
      setDisabledMessage(true);
      setParams({...params, filter: `startswith(/host,'${host}')`})
    } else {
      setDisabledInfo(false);
      setDisabledMsgType(false);
      setDisabledMessage(false);
      setParams({pagesize: 20})
    }
    console.log('host ===> ', host);
  
  }, [host]);
  
  useEffect(() => {
  
    // Disable other fields if info is filled
    if (info.length > 0) {
      setDisabledHost(true);
      setDisabledMsgType(true);
      setDisabledMessage(true);
      setParams({...params, filter: `startswith(/info,'${info}')`})
    } else {
      setDisabledHost(false);
      setDisabledMsgType(false);
      setDisabledMessage(false);
      setParams({pagesize: 20})
    }
  }, [info]);


  useEffect(() => {
    // setParams({
    //   ...params,
    //   param_filter_device_id: host,
    //   param_filter_info: info,
    //   param_filter_message: message,
    //   param_filter_status: msgType, 
    // });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="host"
          errors={errors}
          control={control}
          disabled={disabledHost}
          required
          placeholder="Host"
          maxLength={100}
          isLoading={false}
          onChange={handleHost}
        />
        <InputForm
          name="info"
          errors={errors}
          control={control}
          disabled={disabledInfo}
          required
          placeholder="Info"
          maxLength={100}
          isLoading={false}
          onChange={handleInfo}
        />
        <SelectForm 
          name="msgType"
          defaultValue={1}
          options={optSelect}
          placeholder="Message Type"
          disabled={disabledMsgType}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleMsgTypeSelect}
          isClear
        />
        <InputForm
          name="message"
          errors={errors}
          control={control}
          disabled={disabledMessage}
          required
          placeholder="Message"
          maxLength={100}
          isLoading={false}
          onChange={handleMessage}
        />
      </Box>
    </Box>
  );
};

export default LogEventsSearch;
