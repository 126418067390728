import IRoutes from "../interfaces/IRoutes";
import User from "../pages/user/User";
import UserField from "../pages/user/UserField";

const SatpasDeviceRoute: Array<IRoutes> = [
  {
    element: <User />,
    path: "/user",
  },
  {
    element: <UserField />,
    path: "/user/create",
  },
  {
    element: <UserField />,
    path: "/user/detail/:id",
  }
];

export { SatpasDeviceRoute };
