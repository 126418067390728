
import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';
import { SatpasDeviceRoute } from './user';
import { UserPolicyRoute } from './userPolicy';
import { LogEventsRoute } from './logEvents';
import { MaskingPolicyRoute } from './maskingPolicy';
import { TokenPolicyRoute } from './tokenPolicy';
import { LogResultRoute } from './logResult';
import DailyStatics from '../components/molecules/dashboard/DailyStatics';
import ChangePassword from '../pages/profile/ChangePassword';
import { KeyManagementRoute } from './keyManagement';

const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <DailyStatics />,
        path: '/statistics'
      },
      {
        element: <Login />,
        path: '/login',
      },
      {
        element: <Login />,
        path: '/change-password',
      },
      {
        element: <ChangePassword />,
        path: '/change-new-password',
      },
      {
        element: <Profile />,
        path: '/profile',
      },
      ...SatpasDeviceRoute,
      ...UserPolicyRoute,
      ...KeyManagementRoute,
      ...LogEventsRoute,
      ...LogResultRoute,
      ...MaskingPolicyRoute,
      ...TokenPolicyRoute
    ],
  },
];

export default routes;
