// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const tokenPolicyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTokenPolicyList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/token/policy`,
                    params: params
                }
            },
            providesTags:['GetTokenPolicyList'],
        }),
        getTokenPolicyDetail: builder.mutation<any, any>({
            query: params => {
                return ({
                    url: `/token/policy/${params}`,
                    method: 'GET'
                })
            },
            invalidatesTags:['GetTokenPolicyDetail'],
        }),
        deleteTokenPolicy: builder.mutation<any, any>({
            query: params => {
                return ({
                    url: `/token/policy/${params}`,
                    method: 'DELETE',
                })
            },
            invalidatesTags:['DeleteTokenPolicy', 'GetTokenPolicyList']
        }),
        createTokenPolicyNum: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/token/numeric/policy`,
                    method: 'POST',
                    body
                })
            },
            invalidatesTags:['CreateTokenPolicyNUm', 'GetTokenPolicyList']
        }),
        createTokenPolicyAlpha: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/token/alphanumeric/policy`,
                    method: 'POST',
                    body
                })
            },
            invalidatesTags:['CreateTokenPolicyAlpha', 'GetTokenPolicyList']
        })
  })
})

export const {
useGetTokenPolicyListQuery, 
useGetTokenPolicyDetailMutation,
useDeleteTokenPolicyMutation,
useCreateTokenPolicyNumMutation,
useCreateTokenPolicyAlphaMutation
} = tokenPolicyApiSlice;