// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const userLocalApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserLocalList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/users/local`,
                    params: params
                }
            },
            providesTags:['GetUserLocalList'],
        }),
        createUserLocal: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/user/local`,
                    method: 'POST',
                    body

                })
            },
            invalidatesTags:['CreateUserLocal', 'GetUserLocalList']
        }),
        deleteUserLocal: builder.mutation<any, any>({
            query: params => {
                return ({
                    url: `/user/local/${params}`,
                    method: 'DELETE',
                })
            },
            invalidatesTags:['DeleteUserLocal', 'GetUserLocalList']
        })
  })
})

export const {
useGetUserLocalListQuery,
useCreateUserLocalMutation,
useDeleteUserLocalMutation
} = userLocalApiSlice;