import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const UserSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [enrolledStart, setEnrolledStart] = useState("");
  const [enrolledEnd, setEnrolledEnd] = useState("");
  const [loginStart, setLoginStart] = useState("");
  const [loginEnd, setLoginEnd] = useState("");
  const [userActive, setUserActive] = useState(""); // TODO: NEED PARAMETER FROM API
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    username: string;
    name: string;
    email: string;
    enrolledStart: string;
    enrolledEnd: string;
    loginStart: string;
    loginEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      name: "",
      email: "",
      enrolledStart: "",
      enrolledEnd: "",
      loginStart: "",
      loginEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
    {
      label: "Registered",
      value: "REGISTERED",
    },
  ];

  const handleUsername = (e: string) => {
    setUsername(e);
  };
  const handleName = (e: any) => {
    setName(e);
  };
  const handleEmail = (e: any) => {
    setEmail(e);
  };
  // REGION for Create At
  const handleEnrolledStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(enrolledEnd))) {
        setAllDate(NewDate);
      } else {
        setEnrolledStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      username,
      name,
      email,
      enrolledStart: date,
      enrolledEnd: date,
      loginStart,
      loginEnd,
    });
    setEnrolledStart(date);
    setEnrolledEnd(date);
  };
  const handleEnrolledEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setEnrolledEnd(NewDate);
  };
  const conditionCheck = () => {
    if (enrolledStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Created",
      });
      reset({
        username,
        name,
        email,
        enrolledStart: "",
        enrolledEnd: "",
        loginStart,
        loginEnd,
      });
      setEnrolledEnd("");
      return false;
    } else {
      return true;
    }
  };

  // REGION for login Time

  // const handleLoginStart = (e: any) => {
  //   let NewDate: any;
  //   if (e !== null) {
  //     NewDate = dayjs(e).format("YYYY-MM-DD");
  //   } else {
  //     NewDate = "";
  //   }
  //   setLoginStart(NewDate);
  // };
  // const handleLoginEnd = (e: any) => {
  //   let NewDate: any;
  //   if (e !== null) {
  //     NewDate = dayjs(e).format("YYYY-MM-DD");
  //   } else {
  //     NewDate = "";
  //   }
  //   setLoginEnd(NewDate);
  // };

  const handleLoginStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(loginEnd))) {
        setAllDateLogin(NewDate);
      } else {
        setLoginStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDateLogin(NewDate);
    }
  };
  const setAllDateLogin = (date: string) => {
    reset({
      username,
      name,
      email,
      enrolledStart,
      enrolledEnd,
      loginStart: date,
      loginEnd: date,
    });
    setLoginStart(date);
    setLoginEnd(date);
  };
  const handleLoginEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheckLogin()) setLoginEnd(NewDate);
  };
  const conditionCheckLogin = () => {
    if (loginStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Created",
      });
      reset({
        username,
        name,
        email,
        enrolledStart,
        enrolledEnd,
        loginStart: "",
        loginEnd: "",
      });
      setLoginEnd("");
      return false;
    } else {
      return true;
    }
  };

  useDebouncedEffect(() => setSearchFilterDebounced(username), [username], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(name), [name], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(email), [email], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledStart),
    [enrolledStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledEnd),
    [enrolledEnd],
    500
  );

  useDebouncedEffect(
    () => setSearchFilterDebounced(loginStart),
    [loginStart],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(loginEnd), [loginEnd], 500);

  useEffect(() => {
    setParams({
      ...params,
      username: username,
      name: name,
      email: email,
      created_at_after: enrolledStart,
      created_at_before: enrolledEnd,
      // param_filter_status: userActive,
      login_time_after: loginStart,
      login_time_before: loginEnd,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="username"
          errors={errors}
          control={control}
          required
          placeholder="Username"
          maxLength={100}
          isLoading={false}
          onChange={handleUsername}
        />
        <InputForm
          name="name"
          errors={errors}
          control={control}
          required
          placeholder="Name"
          maxLength={100}
          isLoading={false}
          onChange={handleName}
        />
        <InputForm
          name="email"
          errors={errors}
          control={control}
          required
          placeholder="Email"
          maxLength={100}
          isLoading={false}
          onChange={handleEmail}
        />
        <DatePickerForm
          name="enrolledStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Create"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledStart}
        />
        <DatePickerForm
          name="enrolledEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Create"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledEnd}
        />
        <DatePickerForm
          name="loginStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Login"
          disabled={false}
          isLoading={false}
          required
          onChange={handleLoginStart}
        />
        <DatePickerForm
          name="loginEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Login"
          disabled={false}
          isLoading={false}
          required
          onChange={handleLoginEnd}
        />
        {/* <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        /> */}
      </Box>
    </Box>
  );
};

export default UserSearch;
