import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";

const schema = yup.object({
  title: yup.string().required(),
});

const MaskingPolicySearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [policyName, setPolicyName] = useState("");
  const [keyName, setKeyName] = useState("");
  const [charset, setCharset] = useState("");
  const [pressSufix, setPressSufix] = useState("");
  const [pressPref, setPressPref] = useState("");

  const optSelect = [
    {
      label: "Numeric",
      value: "numeric",
    },
    {
      label: "Alphanumeric",
      value: "alphanumeric",
    },
  ];

  const {
    control,
    formState: { errors },
  } = useForm<{
    policyName: string;
    keyName: string;
    charset: string;
    pressSufix: string;
    pressPref: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      policyName: "",
      keyName: "",
      charset: "",
      pressSufix: "",
      pressPref: "",
    },
  });

  const handlePolicyName = (e: string) => {
    setPolicyName(e);
  };
  const handleKeyName = (e: any) => {
    setKeyName(e);
  };
  const handleCharset = (e: any) => {
    console.log("pulang ===> ", e);
    if (e !== null) {
      setCharset(e.value);
    } else {
      setCharset("");
    }
  };
  const handlePressSufix = (e: any) => {
    setPressSufix(e);
  };
  const handlePressPref = (e: any) => {
    setPressPref(e);
  };

  useDebouncedEffect(
    () => setSearchFilterDebounced(policyName),
    [policyName],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(keyName), [keyName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(charset), [charset], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(pressSufix),
    [pressSufix],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(pressPref),
    [pressPref],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      name: policyName,
      keyName,
      charset,
      preservedSuffixLength: pressSufix,
      preservedPrefixLength: pressPref,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="policyName"
          errors={errors}
          control={control}
          required
          placeholder="Policy Name"
          maxLength={100}
          isLoading={false}
          onChange={handlePolicyName}
        />
        {/* <InputForm
          name="keyName"
          errors={errors}
          control={control}
          required
          placeholder="Key Name"
          maxLength={100}
          isLoading={false}
          onChange={handleKeyName}
        /> */}

        {/* <SelectForm
          name="charset"
          defaultValue={1}
          options={optSelect}
          placeholder="Charset"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleCharset}
          isClear
        /> */}
        <InputForm
          name="pressSufix"
          errors={errors}
          control={control}
          required
          placeholder="Preserved Suffix Length"
          maxLength={100}
          isLoading={false}
          onChange={handlePressSufix}
          type="number"
        />
        <InputForm
          name="pressPref"
          errors={errors}
          control={control}
          required
          placeholder="Preserved Prefix Length"
          maxLength={100}
          isLoading={false}
          onChange={handlePressPref}
          type="number"
        />
      </Box>
    </Box>
  );
};

export default MaskingPolicySearch;
