import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getOcspById,
  removeEventFlag,
  getCertificateDropdown,
} from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import userPolicy from "../../utils/validation/userPolicy";
import {
  useCreateUserPolicyMutation,
  useEditUserPolicyMutation,
} from "../../store/feature/service/userPolicyApiSlice";
import Swal from "sweetalert2";
import { useGetUserLocalListQuery } from "../../store/feature/service/userLocal";
import userPolicyEdit from "../../utils/validation/userPolicyEdit";
import { optAES, optECC, optHMAC, optRSA } from "./optionAlgo";
import keyManagement from "../../utils/validation/keyManagement";
import { useCreateKeyManagementMutation } from "../../store/feature/service/keyManagementApiSlice";

const KeyManagementField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optLength, setOptLength] = useState([]);
  const { editDataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const { uc_ocsp_config_uid } = editDataOcspConfig;

  const [
    createKeyManagement,
    { data, isSuccess: successKeyManagement, isError: errorKeyManagement },
  ] = useCreateKeyManagementMutation();
  const [
    editUserPolicy,
    { data: editUser, isSuccess: successEdit, isError: errorEdit },
  ] = useEditUserPolicyMutation();
  const { data: userLocalList, isLoading } = useGetUserLocalListQuery({});

  const optAlgo = [
    {
      label: "AES",
      value: "AES",
    },
    // {
    //   label: "RSA",
    //   value: "RSA",
    // },
    // {
    //   label: "HMAC",
    //   value: "HMAC",
    // },
    // {
    //   label: "ECC",
    //   value: "ECC",
    // },
  ];

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    keyName: string;
    algorithm: string;
    policyDescription: string;
    keyLength: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(keyManagement),
    defaultValues: {
      keyName: "",
      algorithm: "",
      policyDescription: "",
      keyLength: "",
    },
  });

  const handleAlgo = (e: any) => {
    console.log("hanlde algo ===> ", e);
    if (e.value === "AES") {
      setOptLength(optAES);
    } else if (e.value === "RSA") {
      setOptLength(optRSA);
    } else if (e.value === "HMAC") {
      setOptLength(optHMAC);
    } else if (e.value === "ECC") {
      setOptLength(optECC);
    }
  };

  useEffect(() => {
    if (editFgroup) {
      // setOptAddUserSelect(
      //   userLocalList?.local_users.map((row: any) => ({
      //     label: row.email,
      //     value: row.email,
      //   }))
      // );
      console.log("hahaha ===> ", state.chiper.split('-')[0]);
      // const outputArray = state.principals.reduce((acc: any, item: any) => {
      //   if (item.local_user.username !== "defaultuser") {
      //     acc.push({
      //       label: item.local_user.username,
      //       value: item.local_user.username,
      //     });
      //   }
      //   return acc;
      // }, []);
      reset({
        keyName: state.keyName,
        algorithm: state.chiper.split('-')[0],
        policyDescription: state.description,
        keyLength: state.chiper.split('-')[1],
        // keyLength: outputArray,
        // state.principals.map((item: any)=> (
        //   {
        //     label: item.local_user.username,
        //     value: item.local_user.username
        //   }
        // ))
      });
    }
  }, [state]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      navigate("/user-policy");
      dispatch(removeEventFlag());
    }
  }, [eventFlag]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    console.log("ala toamil goniy ===> ", e);
    // const roleResult = e.role === 'Tokenization Admin Role' ?
    let chiperAll;
    if (e.algorithm !== "ECC") {
      chiperAll = `${e.algorithm}-${e.keyLength}`;
    } else {
      chiperAll = e.keyLength;
    }
    const tempVar = {
      cipher: chiperAll,
      description: e.policyDescription,
      name: e.keyName,
    };

    console.log("tempVal ===> ", tempVar);
    createKeyManagement(tempVar);
  };

  useEffect(() => {
    if (successKeyManagement) {
      Swal.fire({
        icon: "success",
        title: "Success Created",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Created Key Management",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        navigate("/key-management");
      });
    }
  }, [successKeyManagement]);
  useEffect(() => {
    if (errorKeyManagement) {
      Swal.fire({
        icon: "error",
        title: "Error Created",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Created Key Management",
      });
    }
  }, [errorKeyManagement]);
  // useEffect(() => {
  //   if (successEdit) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success Edited",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "User Policy has been edit",
  //     }).then(() => navigate("/user-policy"));
  //   }
  // });
  // if (errorEdit) {
  //   Swal.fire({
  //     icon: "error",
  //     title: "Error Created",
  //     showCancelButton: false,
  //     confirmButtonText: "OK",
  //     confirmButtonColor: "#051438",
  //     text: "Error Edit Token Policy",
  //   });
  // }
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup ? "Detail Key Management" : "Create Key Management"}
        </Typography>
        <InputForm
          name="keyName"
          label="Key Name"
          placeholder="Type Key Name"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="policyDescription"
          label="Description"
          placeholder="Type Policy Description"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {editFgroup ? (
          <>
            <InputForm
              name="algorithm"
              label="Algorithm"
              placeholder="Type Key Name"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <InputForm
              name="keyLength"
              label="Key Length"
              placeholder="Type Policy Description"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </>
        ) : (
          <>
            <SelectFormm
              name="algorithm"
              label="Algorithm"
              defaultValue={0}
              options={optAlgo}
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              isSearch
              required
              onChangeForm={handleAlgo}
            />
            <SelectFormm
              name="keyLength"
              label="Key Length"
              defaultValue={0}
              options={optLength}
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              isSearch
              required
            />
          </>
        )}
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/key-management")}
          >
            {!editFgroup ? 'Cancel' : 'Back'}
          </Button>
          {!editFgroup && (
          <Button variant="contained" type="submit">
            Submit
          </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default KeyManagementField;
