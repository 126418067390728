import { createSlice } from "@reduxjs/toolkit";
import { getToken, setToken, setTokenRegister } from "../../utils/storage";
import { userApiSlice } from "./service/userApiSlice";

export interface ILogin {
  email: string;
  password: string;
  showPass: boolean;
}

export interface IAccess {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  uid: string;
}

export const initialAccess: IAccess = {
  create: false,
  read: false,
  update: false,
  delete: false,
  uid: "",
};

export interface ISubmitLogin {
  rejectMessage: string;
  isLoading: boolean;
  name: string;
  roleTitle: string;
  accesToken: string;
  adminRole: any;
  accessSidebar: any;
  ocspConfig: any;
}

const initialState: ISubmitLogin = {
  rejectMessage: "",
  isLoading: false,
  name: "",
  roleTitle: "",
  accesToken: getToken(),
  adminRole: {},
  accessSidebar: [],
  ocspConfig: initialAccess,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeToken: (state) => {
      state.accesToken = "";
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      userApiSlice.endpoints.submitLogin.matchFulfilled,
      (state: any, action: any) => {
        let token, tokenRegister;
        if (
          action.payload?.message === "user need to change the password first"
        ) {
          tokenRegister = action.payload.access_token;
        } else {
          token = action.payload?.access_token;
        }
        state.accesToken = token;
        setTokenRegister(tokenRegister);
        setToken(token !== undefined ? token : "");
      }
    );
  },
});

export const { removeToken } = userSlice.actions;

export const userReducer = userSlice.reducer;
