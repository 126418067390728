import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteUserPolicyMutation } from "../../../store/feature/service/userPolicyApiSlice";
import { useEffect } from "react";

const UserPolicyTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  totalData,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );

  const [deleteUserPolicy, { data: dataUserPolicy, isSuccess: successDelete, isError }] =
    useDeleteUserPolicyMutation();
  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onDetail = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/user-policy/add/${params.row.id}`, { state: params.row });
    };

    const onDelete = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this User Policy",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUserPolicy({ policy_id: params.row.policyId });
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Add User
          </Button>
          {params.row.role !== "Tokenization Admin Role" ? (
            <Button variant="contained" color="error" onClick={onDelete}>
              Delete
            </Button>
          ) : null}
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "policyName",
      headerName: "Policy Name",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "policyID",
      headerName: "Policy ID",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "revision",
      headerName: "Revision",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 250,
      headerAlign: "center",
      // align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  console.log("data ===> ", data);
  // REGION: INIT ROWS
  const rows = data?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    policyName: row.desc,
    policyID: row.policy_id,
    role: row.role,
    revision: row.revision,
    createdAt: row.created_at,
    policyId: row.policy_id,
    principals: row.principals,
  }));

  useEffect(()=> {
    if(successDelete) {
      Swal.fire({
        icon: "success",
        title: "Success Deleted",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete User Policy",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      })
    }
  })

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete User Policy",
      });
    }
  }, [isError]);

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalData}
      />
    </>
  );
};

export default UserPolicyTable;
