import { apiSlice } from '../api/apiSlice'

  export const livenessDeviceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDashboardInfo: builder.query<any, any>({
            query: params => {
                return {
                    url: `/vault_namespace`,
                    params: params
                }
            },
            providesTags:['GetDashboardInfo'],
        }), 
        updatePassword: builder.mutation<any, any>({
            query: (body) => {
            //   const encryptedOldPassword = encrypt(oldPassword);
            //   const encryptedNewPassword = encrypt(newPassword);
              return {
                url: `/user/change-password`,
                method: "PUT",
                body
              }
            },
            invalidatesTags: ['UpdatePassword']
          }),
  })
})

export const {
useGetDashboardInfoQuery,
useUpdatePasswordMutation
} = livenessDeviceApiSlice;