import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getOcspById,
  removeEventFlag,
  getCertificateDropdown,
} from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import satpasDevice from "../../utils/validation/user";
import SelectForm from "../../components/molecules/forms/selectFormm";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  MailOutline as MailOutlineIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { useCreateUserLocalMutation } from "../../store/feature/service/userLocal";
import Swal from "sweetalert2";

const UserField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state: userData } = location;
  const editFgroup = pathname.includes("detail");
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
  const { editDataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const { uc_ocsp_config_uid } = editDataOcspConfig;

  const [createUserLocal, { isSuccess, isError }] =
    useCreateUserLocalMutation();

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    username: string;
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    roleCode: string;
    // cpnId: string;
    loginIp: string;
    loginTime: string;
    createdDate: string;
    revision: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(satpasDevice),
    defaultValues: {
      uid: "",
      username: "",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      roleCode: "",
      // cpnId: "",
      loginIp: "",
      loginTime: "",
      createdDate: "",
      revision: 0,
    },
  });

  // console.log("created data ===> ", userData);

  // REGION: SET FORM DATA
  // useEffect(() => {
  //   if (editFgroup) {
  //     reset({
  //       uid: uc_ocsp_config_uid,idParam
  //       encryptedString: "",
  //       publicKey: "",
  //     });
  //   } else {
  //     reset();
  //   }
  // }, [ editDataOcspConfig ]);

  // REGION: DISPATCH GET DATA
  // useEffect(() => {
  //   if (idParam.id !== undefined) {
  //     dispatch(getOcspById(idParam.id));
  //   }
  //   dispatch(getCertificateDropdown({}));
  // }, []);

  // // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if(eventFlag) {
  //     navigate("/user")
  //     dispatch(removeEventFlag())
  //   }
  // }, [eventFlag]);
  useEffect(() => {
    if (userData) {
      reset({
        username: userData.username,
        name: userData.name,
        email: userData.email,
        loginIp: userData.loginIp,
        loginTime: userData.loginTime,
        createdDate: userData.createAt,
        revision: userData.revision,
      });
    }
  }, [userData]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    let tempVal = {
      name: e.name,
      password: e.password,
      email: e.email,
      role_code: e.roleCode
      // cpn_id: e.cpnId,
    };
    createUserLocal(tempVal);
    console.log("mantap gan ===> ", e);
  };
  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const endAdornmentConfirm = useMemo(() => {
    if (isShowConfirmPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowConfirmPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setIsShowConfirmPass((prev) => !prev);
  };

  const optSelect = [
    {
      label: "UOPR", 
      value: "UOPR"
    },
    {
      label: "UADM", 
      value: "UADM"
    },
  ]

  if (isError) {
    Swal.fire({
      icon: "error",
      title: "Error Create",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Created User Local",
    });
  }

  if (isSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Create",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Created User Local",
    }).then(() => navigate("/user"));
  }
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup ? "Detail User" : "Create User"}
        </Typography>
        {editFgroup && (
          <InputForm
            name="username"
            label="Username"
            disabled={editFgroup}
            isLoading={false}
            control={control}
            errors={errors}
            maxLength={500}
            required
          />
        )}
        <InputForm
          name="name"
          label="Name"
          placeholder="Type Username"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="email"
          label="Email"
          placeholder="Type Email"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {!editFgroup && (
          <>
            <InputForm
              name="password"
              label="Password"
              placeholder="Type Passsword"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={127}
              required
              type={isShowPass ? "text" : "password"}
              showEndAdornment
              sxEndAdornment={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                width: "4rem",
              }}
              endAdornment={endAdornment}
              onClickEndAdornment={toggleShowPassword}
              sxFC={{ marginBottom: "1.5rem" }}
            />
            <InputForm
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Type Confirm Password"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={127}
              required
              type={isShowConfirmPass ? "text" : "password"}
              showEndAdornment
              sxEndAdornment={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                width: "4rem",
              }}
              endAdornment={endAdornmentConfirm}
              onClickEndAdornment={toggleShowConfirmPassword}
              sxFC={{ marginBottom: "1.5rem" }}
            />
            {/* <InputForm
              name="roleCode"
              label="Role Code"
              placeholder="Type Role Code"
              disabled={true}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            /> */}
            <SelectFormm
              name="roleCode"
              label="Role Code"
              defaultValue={0}
              options={optSelect}
              placeholder="Please Select"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
            {/* <InputForm
              name="cpnId"
              label="CPN ID"
              placeholder="Type CPN ID"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            /> */}
          </>
        )}
        {editFgroup && (
          <>
            <InputForm
              name="loginIp"
              label="Login IP"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <InputForm
              name="loginTime"
              label="LoginTime"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <InputForm
              name="createdDate"
              label="Created Date"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <InputForm
              name="revision"
              label="Revision"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </>
        )}
        {/* <SelectForm
          name="publicKey"
          label="Public Key"
          defaultValue=""
          placeholder="Input Public Key here.."
          disabled={editFgroupe}
          isLoading={false}
          control={control}
          errors={errors}
          required
          options={[
            {
              label: "master",
              value: "Master",
            },
            {
              label: "pro",
              value: "Pro",
            },
          ]}
        /> */}
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/user")}
          >
            {editFgroup ? "Back" : "Cancel"}
          </Button>
          {/* {editFgroup && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )} */}
          {!editFgroup && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default UserField;
