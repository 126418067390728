import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DnsIcon from '@mui/icons-material/Dns';
import DvrIcon from '@mui/icons-material/Dvr';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    icon: <DashboardTwoToneIcon />,
  },
  { 
    key: 'user',
    name: 'Local User',
    path: '/user',
    icon: <FeaturedPlayListIcon />,
  },
  { 
    key: 'user-policy',
    name: 'User Policy',
    path: '/user-policy',
    icon: <DnsIcon />,
  },
  // { 
  //   key: 'log',
  //   name: 'Log',
  //   path: '',
  //   icon: <DvrIcon />,
  //   children: [
  //     { 
  //       key: 'event-log',
  //       name: 'Event Logs',
  //       path: '/event-log',
  //       icon: <DvrIcon />,
  //     },
  //     { 
  //       key: 'log-result',
  //       name: 'Log Result',
  //       path: '/log-result',
  //       icon: <DvrIcon />,
  //     },
  //   ]
  // },
  { 
    key: 'key-management',
    name: 'Key Management',
    path: '/key-management',
    icon: <DvrIcon />,
  },
  { 
    key: 'masking-policy',
    name: 'Masking Policy',
    path: '/masking-policy',
    icon: <ManageAccountsIcon />,
  },
  { 
    key: 'token-policy',
    name: 'Token Policy',
    path: '/token-policy',
    icon: <DevicesOtherIcon />,
  },
  { 
    key: 'event-log',
    name: 'Event Log',
    path: '/event-log',
    icon: <DvrIcon />,
  },
];
