import "chart.js/auto";
import { Paper, Typography, Box, Grid } from "@mui/material";
import DailyStatics from "../../components/molecules/dashboard/DailyStatics";
import HeaderDashboard from "./HeaderDashboard";
import DashboardGrid from "./DashboardGrid";
// import { useGetProfileDetailMutation } from "../../store/feature/service/userApiSlice";
import { useEffect } from "react";
import { useGetDashboardInfoQuery } from "../../store/feature/service/dashboard";
import entrustLogo from "../../assets/logo/entrustWhite.png";
import dayjs from "dayjs";

const Dashboard = () => {
  const { data } = useGetDashboardInfoQuery({});
  const currentDate = dayjs();
  const currentDateOnly = dayjs().format("YYYY-MM-DD");
  // const [getProfileDetail] = useGetProfileDetailMutation();
  console.log("informasi ===> ", data);
  // useEffect(()=> {
  //   getProfileDetail({})
  // }, [])

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
        padding: "40px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Typography variant="h2bold" sx={{ mb: 3 }}>
            Welcome, {data?.adm_user_email}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography variant="h5bold" sx={{ mb: 2 }}>
            {currentDateOnly}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography variant="body14Semibold">
            Tokenization information <br />
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="body14Semibold">
            {/* Tokenization information <br /> */}
            Namespace(vault id) <br />
            you are login as
            {/* Current Date : {currentDateOnly} <br />
      Dashboard Protection domain and key Information <br />
      KeyManagement Type: {dashboardData.type}
      <br />
      EventLog Group ID: {dashboardData.groupid}
      <br />
      Protected Domain: {dashboardData.protection} <br />
      Softcard Name: {dashboardData.name} <br />
      keys link: {dashboardData.keys}
      <br /> */}
          </Typography>
        </Grid>
        <Grid item lg={9}>
          <Typography variant="body14Semibold">
            : {data?.tokn_comp_vault_namespace} <br />:{" "}
            {data?.adm_role_code === "UADM"
              ? "Admin User"
              : data?.adm_role_code === "UOPR"
              ? "Operator User"
              : "as is"}
          </Typography>
        </Grid>
      </Grid>
      <img
        src={entrustLogo}
        alt="Description of image"
        style={{
          width: 180,
          height: 50,
          position: "absolute",
          bottom: 0,
          right: 0,
          margin: "10px",
        }}
      />
    </Box>
  );
};

export default Dashboard;
