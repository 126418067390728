import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import {
  getAllOcspList,
  removeEventFlag,
} from "../../store/feature/satpasDevice";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import MaskingPolicySearch from "../../components/molecules/maskingPolicy/MaskingPolicySearch";
import MaskingPolicyTable from "../../components/molecules/maskingPolicy/MaskingPolicyTable";
import { useGetMaskingPolicyListQuery } from "../../store/feature/service/maskingPolicyApiSlice";

const MaskingPolicy = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data: dataMaskingPolicy, isLoading } =
    useGetMaskingPolicyListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    console.log("dataMaskingPolicy ===> ", dataMaskingPolicy);
  }, [dataMaskingPolicy]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Masking Policy
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/masking-policy/create")}
          sx={{ marginBottom: "18px" }}
        >
          <AddIcon /> Create
        </Button>
        <MaskingPolicySearch params={params} setParams={setParams} />
        <MaskingPolicyTable
          data={
            dataMaskingPolicy !== undefined &&
            dataMaskingPolicy?.mask_policies !== null
              ? dataMaskingPolicy?.mask_policies
              : []
          }
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          totalData={dataMaskingPolicy?.count}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default MaskingPolicy;
