import IRoutes from "../interfaces/IRoutes";
import UserPolicy from "../pages/userPolicy/UserPolicy";
import UserPolicyField from "../pages/userPolicy/UserPolicyField";

const UserPolicyRoute: Array<IRoutes> = [
    {
        element: <UserPolicy />,
        path: "/user-policy",
    },
    {
        element: <UserPolicyField />,
        path: "/user-policy/create",
    },
    {
        element: <UserPolicyField />,
        path: "/user-policy/add/:id",
    },
];

export { UserPolicyRoute };
