import * as yup from 'yup';
import {
  validationConfirmNewPassword2,
  validationConfirmNewPasswordL,
  validationEmailField,
  validationNewPassword,
  validationTextField,
} from './defaultValidations';

export default yup.object().shape({
  Email: validationEmailField('Email', 8, 100, 'Your Email is invalid.'),
  Password: validationTextField('Password', 5, 127),
  newPassword: validationNewPassword('New Password', 5, 127),
  confirmNewPassword: validationConfirmNewPassword2("Confirm New Password")
});
