import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getOcspById,
  removeEventFlag,
  getCertificateDropdown,
} from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import userPolicy from "../../utils/validation/userPolicy";
import {
  useCreateUserPolicyMutation,
  useEditUserPolicyMutation,
} from "../../store/feature/service/userPolicyApiSlice";
import Swal from "sweetalert2";
import { useGetUserLocalListQuery } from "../../store/feature/service/userLocal";
import userPolicyEdit from "../../utils/validation/userPolicyEdit";

const UserPolicyField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("add");
  const [optAddUserSelect, setOptAddUserSelect] = useState([]);
  const { editDataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const { uc_ocsp_config_uid } = editDataOcspConfig;

  const [createUserPolicy, { data, isSuccess, isError }] =
    useCreateUserPolicyMutation();
  const [
    editUserPolicy,
    { data: editUser, isSuccess: successEdit, isError: errorEdit },
  ] = useEditUserPolicyMutation();
  const { data: userLocalList, isLoading } = useGetUserLocalListQuery({});
  // const optAddUserSelect = [
  //   {
  //     label: "Active",
  //     value: "Active",
  //   },
  //   {
  //     label: "Inactive",
  //     value: "Inactive",
  //   },
  // ];

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    policyName: string;
    revision: string;
    policyDescription: string;
    addUser: any[];
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(!editFgroup ? userPolicy : userPolicyEdit),
    defaultValues: {
      policyName: "",
      revision: "",
      policyDescription: "",
      addUser: [],
    },
  });

  // REGION: SET FORM DATA
  // useEffect(() => {
  //   if (editFgroup) {
  //     reset({
  //       uid: uc_ocsp_config_uid,
  //       serverHostname: "",
  //       addUser: "",
  //       policyDescription: "",
  //     });
  //   } else {
  //     reset();
  //   }
  // }, [ editDataOcspConfig ]);

  // REGION: DISPATCH GET DATA
  // useEffect(() => {
  //   if (idParam.id !== undefined) {
  //     dispatch(getOcspById(idParam.id));
  //   }
  //   dispatch(getCertificateDropdown({}));
  // }, []);

  useEffect(() => {
    console.log("state ===> ", state);
    setOptAddUserSelect(
      userLocalList?.local_users.map((row: any) => ({
        label: row.email,
        value: row.email,
      }))
    );
    if (editFgroup) {
      const outputArray = state.principals.reduce((acc: any, item: any) => {
        if (item.local_user.username !== "defaultuser") {
          acc.push({
            label: item.local_user.username,
            value: item.local_user.username,
          });
        }
        return acc;
      }, []);
      reset({
        policyName: state.policyId,
        revision: state.revision,
        addUser: outputArray,
        // state.principals.map((item: any)=> (
        //   {
        //     label: item.local_user.username,
        //     value: item.local_user.username
        //   }
        // ))
      });
    }
  }, [state, userLocalList]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      navigate("/user-policy");
      dispatch(removeEventFlag());
    }
  }, [eventFlag]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    const prinsips = e.addUser.map((row: any) => ({
      local_user: {
        username: row.value,
      },
    }));
    // const roleResult = e.role === 'Tokenization Admin Role' ?
    const tempVar = {
      desc: e.policyName,
      name: e.policyDescription,
      principals: prinsips,
      role: "Tokenization User Role",
    };

    // console.log("editVar ===> ", editVar);
    // console.log("tempVar ===> ", state.role);
    if (editFgroup) {
      const editVar = {
        policy_id: e.policyName,
        revision: e.revision,
        username: e.addUser.map((row: any) => row.value),
        type: state.role === "Tokenization Admin Role" ? "admin" : "operator",
      };
      editUserPolicy(editVar);
    } else {
      createUserPolicy(tempVar);
    }
  };

  if (isSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Created",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "User Policy has been created",
    }).then(() => navigate("/user-policy"));
  }

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Created",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Created Token Policy",
      });
    }
  }, [isError]);
  useEffect(() => {
    if (successEdit) {
      Swal.fire({
        icon: "success",
        title: "Success Edited",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "User Policy has been edit",
      }).then(() => navigate("/user-policy"));
    }
  });
  if (errorEdit) {
    Swal.fire({
      icon: "error",
      title: "Error Created",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Edit Token Policy",
    });
  }
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup ? "Add User Policy" : "Create User Policy"}
        </Typography>
        <InputForm
          name="policyName"
          label="Policy Name"
          placeholder="Type Policy Name"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {!editFgroup && (
          <>
            <InputForm
              name="policyDescription"
              label="Policy Description"
              placeholder="Type Policy Description"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <SelectFormm
              name="addUser"
              label="Add to User Policy"
              defaultValue={0}
              options={optAddUserSelect}
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              isSearch
              required
              isMulti
            />
          </>
        )}
        {editFgroup && (
          <>
            <InputForm
              name="revision"
              label="Revision"
              placeholder="Server URL"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <SelectFormm
              name="addUser"
              label="Add to User Policy"
              defaultValue={0}
              options={optAddUserSelect}
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              isSearch
              required
              isMulti
            />
          </>
        )}
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/user-policy")}
          >
            Cancel
          </Button>
          {/* {editFgroup && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )} */}
          {/* {editFgroup && ( */}
          <Button variant="contained" type="submit">
            Submit
          </Button>
          {/* )} */}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default UserPolicyField;
